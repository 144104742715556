/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				jQuery( document ).ready( function ( $ ) {
					if ( innerWidth>1200 ) {
						setTimeout( function () {
							if( $crisp && $crisp.on ) {
								console.log( $crisp );
								$crisp.on( "chat:opened", function () {
									$("#crisp-chatbox > div > div").css("cssText", "width: 350px !important;");
									$('#crisp-chatbox div.crisp-zyxrmn').css("cssText", "height: 350px !important;");
									setTimeout( function () {
										$('#crisp-chatbox > div > div').css("cssText", "width: 350px !important;");
										$('#crisp-chatbox div.crisp-zyxrmn').css("cssText", "height: 350px !important;");
									} );
								});
							}
							if( $crisp && $crisp.do ) {
								console.log( 1 );
								$crisp.do('chat:open');
							}
							$crisp.push(["on", "session:loaded", function () {
								console.log( 2 );
								$crisp.do('chat:open');
							}]);
						}, 1000 );
					};
				} );

				$('.search-form').css("display", "flex").hide();
				$('.show_search').click( function (e) {
					e.preventDefault();
					$(this).parent().find( '.search-form').fadeToggle();
				} );
				$('ul.nav li.dropdown').hover(function() {
				  $(this).find('> .dropdown-menu').stop(true, true).delay(200).fadeIn(500);
				}, function() {
				  $(this).find('> .dropdown-menu').stop(true, true).delay(200).fadeOut(500);
				});
				
				$('#back-to-top').click(function () {
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					return false;
				});
				$('.orderby').select2({
					width: "element" 
				});
				$('.woocommerce div.product form.cart .variations select').select2({
					width: "element" 
				});
				var sourceSwap = function () {
					var $this = $(this).find('img');
					var newSource = $this.data('alt-src');
					$this.data('alt-src', $this.attr('src'));
					$this.attr('src', newSource);
				};
				$('.login_reparto_carrito li').hover(sourceSwap, sourceSwap);
				//$('img[data-alt-src]')

				$('.showlogin').click( function ( e ) {
					e.preventDefault();
					$('.open_login_register').click();
				} );
				$('.openPublicar a, .openPublicar_2').click( function ( e ) {
					e.preventDefault();
					$('.open_login_register').click();
					$('.mfp-content [data-redirect="current"]').attr( 'data-redirect', $(this).attr('href') );
					setTimeout( function () {
						$('.mfp-content [data-redirect="current"]').attr( 'data-redirect', $(this).attr('href') );
					}, 500 );
				} );
				$('.open_login_register, .open_register').magnificPopup({
					'type': 'inline',
					'midClick': true,
					'fixedContentPos': true,
					'fixedBgPos': true,
				});
				$('a.go_top').click( function ( e ) {
					e.preventDefault();
					$('html, body').animate({
						scrollTop: 0
					}, 1500);
				});
				/*
				$('.navbar-toggler').click( function ( e ) {
					e.preventDefault();
					$('#navbarSupportedContent').toggleClass('openleft');
					
					if( jQuery('#navbarSupportedContent').hasClass('openleft') ) {
						$('body').css( {
							'overflow-y': 'hidden',
						} );
					} else {
						$('body').css( {
							'overflow-y': 'auto',
						} );
					}

				$('.navbar-toggler').magnificPopup({
					'type': 'inline',
					'midClick': true,
					'fixedContentPos': true,
					'fixedBgPos': true,
					callbacks: {
						resize: function() {
							if ( window.innerWidth >= 1260 ) {
								$.magnificPopup.close();
							}
						},
					}
				});
				*/
				$('a[href="#openSearch"]').click( function (e) {
					e.preventDefault();
					$(this).parent().find( '.search-form').fadeToggle();
					$(this).parent().find( '.search-form .search-field').focus();
					$(this).parent().find( '.search-form .search-field')[0].selectionStart = $(this).parent().find( '.search-form .search-field')[0].selectionEnd = $(this).parent().find( '.search-form .search-field').val().length;
				} );
				$('[name="user_login"]').change( function ( e ) {
					$('#reggister_user_name').val( $(this).val() );
					$('#reggister_user_email').val( $(this).val() );
					$('#reggister_log').val( $(this).val() );
				} );
				$('#open_login_register form').submit( function ( e ) {
					var $form = $(this);
					e.preventDefault();
					$form.find('.alert').remove();
					var data = {
						'action': 'loginWithEmail',
						'email': $form.find('[name="log"]').val(),
						'password': $form.find('[name="pwd"]').val(),
					};
					jQuery.post(ajax_object.ajax_url, data, function(response) {
						console.log( response );
						if ( response.result == 'success' ) {
							$form.prepend('<div class="alert alert-success" style="padding-top: 0.3em; padding-bottom: 0.1em; " role="alert">'+response.msg+'</div>');
							setTimeout( function ( ) {
								location.reload();
							}, 100 );
						}
						else {
							$form.prepend('<div class="alert alert-danger" style="padding-top: 0.3em; padding-bottom: 0.1em; " role="alert">'+response.msg+'</div>');
						}
					});
				} );
				var $window = $(window);
				var resize = false;
				var timer;
				$('.flexslider').on('resize',function(event){
					//event.stopPropagation();
				});
				var resize_loop = 0;
				$window.resize(function() {
					clearTimeout(timer);
					if ( resize_loop < 1 ) {
						timer = setTimeout( function(){
							console.log('resize');
							var slider1 = $('.flexslider').data('flexslider');
							if( typeof slider1 != 'undefined' ) {
								slider1.resize();
							}
							resize_loop++;
						}, 500);
					} else {
						console.log('resize 2');
						setTimeout( function() {
							resize_loop = 0;
						}, 500);
					}
					/*
					if ( !resize ) {
						setTimeout( function () {
							resize = true;
						} );
					};
					*/
				});
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'single_product': {
			getGridSize: function( xs, sm, md, lg, xl, xxl ) {
				return ( window.innerWidth < 544 ) ? xs : ( window.innerWidth < 768 ) ? sm : ( window.innerWidth < 992 ) ? md : ( window.innerWidth < 1260 ) ? lg: ( window.innerWidth < 1440 ) ? xl: xxl;
			},
			initSliders: function ( ) {
				var _this = this;
				$('.releted_products.flexslider').flexslider({
					animation: "slide",
					itemWidth: 280,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(1, 2, 3, 3, 4, 4),
					maxItems: _this.getGridSize(1, 2, 3, 3, 4, 4),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderProducts = slider;
					}
				});

				$('.thumbnails .slides > li').fadeOut(0);
				$(".thumbnails .slides > li[data-attribute_general='true']").fadeIn(0);
				$('.thumbnails.flexslider').flexslider({
					selector: ".slides > li:not([style*='display: none'])",
					animation: "slide",
					itemWidth: 230,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(2, 2, 3, 4, 4, 6),
					maxItems: _this.getGridSize(2, 2, 3, 4, 4, 6),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					slideshow: false,
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderThumbnails = slider;
						$('.variations select').change();

						/*
						var yith_wcmg_image = $('.yith_magnifier_zoom img');
						yith_wcmg_image.elevateZoom({
							gallery: isMobile.any?'woocommerce_product_thumbnails_mobile':'woocommerce_product_thumbnails_pc',
							cursor: 'pointer',
							galleryActiveClass: 'active',
							borderSize: 1,
							imageCrossfade: true,
							loadingIcon: ''
						});
						*/
					}
				});
			},
			flexsliderThumbnails: { vars:{} },
			flexsliderProducts: { vars:{} },
			destroySlider: function() {
				var _this = this;
				if ( isMobile.any ) {
					$('.releted_products.flexslider').removeData("flexslider");
					$('.thumbnails.flexslider').removeData("flexslider");
					_this.initSliders();
				}
				else {
					var gridSizeProducts = _this.getGridSize( 1, 2, 3, 3, 4, 4 );
					var gridSizeThumbnails = _this.getGridSize( 1, 2, 3, 3, 3, 3 );
					_this.flexsliderProducts.vars.minItems = gridSizeProducts;
					_this.flexsliderThumbnails.vars.maxItems = gridSizeThumbnails;
				}
			},
			init: function() {
				// JavaScript to be fired on the home pagevar 
				var _this = this;
				var $window = $(window);
				_this.initSliders();

				$window.resize(function() {
					if ( isMobile.any ) {
						_this.destroySlider();
					}
					else {
						var gridSizeProducts = _this.getGridSize( 1, 2, 3, 3, 3, 3 );
						var gridSizeThumbnails = _this.getGridSize( 1, 2, 3, 3, 3, 3 );
						_this.flexsliderProducts.vars.minItems = gridSizeProducts;
						_this.flexsliderThumbnails.vars.maxItems = gridSizeThumbnails;
					}
				});
			},
			finalize: function() {
				var _this = this;
				// JavaScript to be fired on the home page, after the init JS
				setTimeout( function () {
					_this.destroySlider();
				}, 1000);
				setTimeout( function () {
					_this.destroySlider();
				}, 2000);

				var yith_wcmg_gallery = $('.woocommerce-product-gallery__wrapper .product_thumbnails .woocommerce-product-gallery__image a');
				var yith_wcmg_image = $('.woocommerce-product-gallery__wrapper .product_featured_image .woocommerce-product-gallery__image img');
				$('.woocommerce-product-gallery__wrapper .product_featured_image .woocommerce-product-gallery__image a').click( function ( e ) {
					e.preventDefault();
				} );
				yith_wcmg_gallery.click( function ( e ) {
					e.preventDefault();
					var _yith_wcmg_image = $(this).attr('href');
					yith_wcmg_image.attr('src', _yith_wcmg_image);
					yith_wcmg_image.data('src', _yith_wcmg_image);
					yith_wcmg_image.attr('srcset', _yith_wcmg_image);
					yith_wcmg_image.parent().attr('href', _yith_wcmg_image);
					/*
					var ez = yith_wcmg_image.data('elevateZoom');	
					ez.imageSrc = _yith_wcmg_zoom;
					ez.currentImage = _yith_wcmg_zoom;
					ez.zoomImage = _yith_wcmg_zoom;
					ez.refresh(0);
					*/
				} );
				
				/*
				var $form = $('form.variations_form');
				var yith_wcmg = $('.images');
				
				var yith_wcmg_zoom = $('.yith_magnifier_zoom');
				var yith_wcmg_image = $('.yith_magnifier_zoom img');

				var yith_wcmg_default_zoom = yith_wcmg.find('.yith_magnifier_zoom').attr('href');
				var yith_wcmg_default_image = yith_wcmg.find('.yith_magnifier_zoom img').attr('src');
				yith_wcmg_zoom.click( function ( e ) {
					e.preventDefault();
				} );
				yith_wcmg_image.elevateZoom({
					gallery: isMobile.any?'woocommerce_product_thumbnails_mobile':'woocommerce_product_thumbnails_pc',
					zoomType: isMobile.any?'inner':'window',
					cursor: 'pointer',
					//galleryActiveClass: 'active',
					borderSize: 1,
					imageCrossfade: true,
					loadingIcon: ''
				});
				*/
				/*
				jQuery('.variations select').change( function () {
					var seleted = false;
					var _itemSelect = false;
					jQuery('.variations select').each( function ( indexSelect, itemSelect ) {
						if ( jQuery(itemSelect).val() ) {
							if ( !_itemSelect ) {
								_itemSelect = itemSelect;
							}
							else {
								_itemSelect = false;
							}
						}
					} );
					console.log( _itemSelect );
					if ( _itemSelect ) {
						if ( jQuery(_itemSelect).val() ) {
							jQuery('.thumbnails .slides > li').fadeOut(0);
							jQuery('.thumbnails .slides > li[data-'+ jQuery(_itemSelect).data('attribute_name') +']').each( function( index, item ) {
								var attributes = jQuery(item).data( jQuery(_itemSelect).data('attribute_name') ).split(',');
								if ( jQuery.inArray( jQuery(_itemSelect).val(), attributes ) !== -1 ) {
									console.log( jQuery(item), jQuery(_itemSelect).val(), attributes );
									jQuery(item).fadeIn(0);
								}
							} );
						}
					}
				} );
				*/
				/*
				$(document).on('found_variation', 'form.variations_form', function (event, variation) {
					jQuery('.zoomContainer').remove();
					
					var image_magnifier = variation.image_link ? variation.image_link : yith_wcmg_default_zoom;
					var image_src = variation.image_src ? variation.image_src : yith_wcmg_default_image;
					yith_wcmg_zoom.attr('href', image_magnifier);
					yith_wcmg_image.attr('src', image_src);
					yith_wcmg_image.attr('srcset', image_src);
					yith_wcmg_image.attr('data-zoom-image', image_magnifier);
					yith_wcmg_image.attr('src-orig', image_src);
					var ez = yith_wcmg_image.data('elevateZoom');	
					ez.imageSrc = image_magnifier;
					ez.currentImage = image_magnifier;
					ez.zoomImage = image_magnifier;
					ez.refresh(0);
					$('.thumbnails.flexslider').removeData("flexslider");
					$('.thumbnails.flexslider').removeData("destroy");
					$('.thumbnails .slides > li').fadeOut(0);
					if ( variation.attributes ) {
						var data_attrs_str = '';
						var data_atribute = [];
						var data_attrs = [];
						for (var keyAttr in variation.attributes) {
							data_attrs_str+='[data-'+ keyAttr +']';
							data_atribute.push( variation.attributes[keyAttr] );
							data_attrs.push( keyAttr );
						}
						$('.thumbnails .slides > li'+data_attrs_str).each( function( index, item ) {
							var include = true;
							for (var x = 0; x < data_attrs.length; x++) {
								var attributes = $(item).data( data_attrs[x] ).split(',');
								if ( $.inArray( data_atribute[x], attributes ) === -1 ) {
									include = false;
								};
								//console.log( data_atribute[x], attributes, index, include );
							}
							if ( include ) {
								$(item).fadeIn(0);
							}
						} );
						setTimeout( function () {
							$('.thumbnails .slides > li').fadeOut(0);
							$('.thumbnails .slides > li'+data_attrs_str).each( function( index, item ) {
								var include = true;
								for (var x = 0; x < data_attrs.length; x++) {
									var attributes = $(item).data( data_attrs[x] ).split(',');
									if ( $.inArray( data_atribute[x], attributes ) === -1 ) {
										include = false;
									};
									//console.log( data_atribute[x], attributes, index, include );
								}
								if ( include ) {
									$(item).fadeIn(0);
								}
							} );
							$('.thumbnails.flexslider').flexslider({
								animation: "slide",
								selector: ".slides > li:not([style*='display: none'])",
								itemWidth: 230,
								pauseOnAction: false,
								pauseOnHover: true,
								itemMargin: 0,
								minItems: _this.getGridSize(2, 2, 3, 4, 4, 6),
								maxItems: _this.getGridSize(2, 2, 3, 4, 4, 6),
								controlNav: false,
								animationLoop: true,
								prevText: "",
								nextText: "",
								slideshow: false,
							} );
						}, 500 );
						return;
						for (var keyAttr in variation.attributes) {
							$('.thumbnails .slides > li[data-'+ keyAttr +']').each( function( index, item ) {
								var attributes = $(item).data( keyAttr ).split(',');
								console.log( variation.attributes[keyAttr], attributes );
								if ( $.inArray( variation.attributes[keyAttr], attributes ) !== -1 ) {
									$(item).fadeIn(0);
								}
							} );
							setTimeout( function () {
								$('.thumbnails .slides > li').fadeOut(0);
								$('.thumbnails .slides > li[data-'+ keyAttr +']').each( function( index, item ) {
									var attributes = $(item).data( keyAttr ).split(',');
									if ( $.inArray( variation.attributes[keyAttr], attributes ) !== -1 ) {
										$(item).fadeIn(0);
									}
								} );
								$('.thumbnails.flexslider').flexslider({
									animation: "slide",
									selector: ".slides > li:not([style*='display: none'])",
									itemWidth: 230,
									pauseOnAction: false,
									pauseOnHover: true,
									itemMargin: 0,
									minItems: _this.getGridSize(2, 2, 3, 4, 4, 6),
									maxItems: _this.getGridSize(2, 2, 3, 4, 4, 6),
									controlNav: false,
									animationLoop: true,
									prevText: "",
									nextText: "",
									slideshow: false,
								} );
							}, 500 );
						}
					}

				}).on('reset_image', function (event) {
					jQuery('.zoomContainer').remove();
					yith_wcmg_zoom.attr('href', yith_wcmg_default_zoom);
					yith_wcmg_image.attr('src', yith_wcmg_default_image);
					yith_wcmg_image.attr('data-zoom-image', yith_wcmg_default_image);
					yith_wcmg_image.attr('srcset', yith_wcmg_default_image);
					yith_wcmg_image.attr('src-orig', yith_wcmg_default_image);
					var ez = yith_wcmg_image.data('elevateZoom');	
					ez.imageSrc = yith_wcmg_default_zoom;
					ez.currentImage = yith_wcmg_default_zoom;
					ez.zoomImage = yith_wcmg_default_zoom;
					ez.refresh(0);
					$('.thumbnails .slides > li').fadeOut(0);
					$('.thumbnails .slides > li[data-attribute_general="true"]').fadeIn(0);
					$('.thumbnails.flexslider').removeData("flexslider");
					$('.thumbnails.flexslider').removeData("destroy");
					$('.thumbnails .slides > li').fadeOut(0);
					$('.thumbnails .slides > li[data-attribute_general="true"]').fadeIn(0);
					setTimeout( function () {
						$('.thumbnails.flexslider').flexslider({
							animation: "slide",
							selector: ".slides > li:not([style*='display: none'])",
							itemWidth: 230,
							pauseOnAction: false,
							pauseOnHover: true,
							itemMargin: 0,
							minItems: _this.getGridSize(2, 2, 3, 4, 4, 6),
							maxItems: _this.getGridSize(2, 2, 3, 4, 4, 6),
							controlNav: false,
							animationLoop: true,
							prevText: "",
							nextText: "",
							slideshow: false,
						} );
					}, 500 );
				});
				*/
			}
		},
		// Home page
		'home': {
			readyVideo: function (player_id) {
				var froogaloop = $(player_id);
				froogaloop.addEvent('play', function(data) {
					$('.widget_products_slider .flexslider').flexslider("pause");
				});

				froogaloop.addEvent('pause', function(data) {
					$('.widget_products_slider .flexslider').flexslider("play");
				});
			},
			getGridSize: function( xs, sm, md, lg, xl, xxl ) {
				return ( window.innerWidth < 544 ) ? xs : ( window.innerWidth < 768 ) ? sm : ( window.innerWidth < 992 ) ? md : ( window.innerWidth < 1260 ) ? lg: ( window.innerWidth < 1440 ) ? xl: xxl;
			},
			initSliders: function ( ) {
				var _this = this;
				var total_width = 0;
				$('#list_socios_flexslider .slides > li').each(function() {
					total_width+=$(this).width();
				});
				var avg_width = total_width / $('#list_socios_flexslider .slides > li').length;
				$('#list_socios_flexslider').flexslider({
					animation: "slide",
					slideshowSpeed:5000,
					animationSpeed:350,
					//itemWidth: avg_width,
					itemWidth: isMobile.any ? 280 : ( window.innerWidth > 1360 ? ( window.innerWidth > 1440 ? 195 : 203 ) : 190),
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: 1,
					maxItems: 10,
					initDelay: 3000,
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
				});
				$('.widget_products_slider .flexslider').flexslider({
					animation: "slide",
					//animationLoop: false,
					itemWidth: 320,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(1, 2, 3, 4, 4, 5),
					maxItems: _this.getGridSize(1, 2, 3, 4, 4, 5),
					controlNav: true,
					animationLoop: true,
					prevText: "",
					nextText: "",
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderProducts = slider;
					}
				});
				function addEvent(element, eventName, callback) {
					if (element.addEventListener) {
						element.addEventListener(eventName, callback, false);
					} else {
						element.attachEvent(eventName, callback, false);
					}
				}
				
				var vimeoPlayers = $('.widget_wc_videos_slider .flexslider').find('iframe'), player;
				for (var i = 0, length = vimeoPlayers.length; i < length; i++) {
					player = vimeoPlayers[i];
					//$(player).addEvent('ready', _this.readyVideo);
				}
				
				$('.widget_wc_videos_slider .flexslider').flexslider({
					animation: "slide",
					//animationLoop: false,
					itemWidth: 192,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(1, 2, 3, 2, 2, 2),
					maxItems: _this.getGridSize(1, 2, 3, 2, 2, 2),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderVideos = slider;
					},
					before: function(slider){
						for (var i = 0, length = vimeoPlayers.length; i < length; i++) {
							//$(vimeoPlayers[i]).api('pause');
						}
					}
				});
				$('.widget_wc_posts_slider .flexslider').flexslider({
					animation: "slide",
					//animationLoop: false,
					itemWidth: 426,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					//minItems: 1,
					//maxItems: 4,
					minItems: _this.getGridSize(1, 2, 2, 3, 3, 3),
					maxItems: _this.getGridSize(1, 2, 2, 3, 3, 3),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderPosts = slider;
					}
				});
			},
			flexsliderPosts: { vars:{} },
			flexsliderProducts: { vars:{} },
			flexsliderVideos: { vars:{} },
			destroySlider: function() {
				var _this = this;
				$('.widget_products_slider .flexslider').removeData("flexslider");
				$('.widget_wc_videos_slider .flexslider').removeData("flexslider");
				$('.widget_wc_posts_slider .flexslider').removeData("destroy");

				$('#list_socios_flexslider').removeData("flexslider");
				$('#list_socios_flexslider').removeData("destroy");
				_this.initSliders();
			},
			init: function() {
				// JavaScript to be fired on the home pagevar 
				var _this = this;
				var $window = $(window);

				_this.initSliders();
				$window.resize(function() {
					if ( isMobile.any ) {
						_this.destroySlider();
					}
					else {

						var gridSizePosts = _this.getGridSize( 1, 2, 2, 3, 3, 3 );
						_this.flexsliderPosts.vars.minItems = gridSizePosts;
						_this.flexsliderPosts.vars.maxItems = gridSizePosts;

						var gridSizeVideos = _this.getGridSize( 1, 2, 3, 2, 2, 2 );
						_this.flexsliderVideos.vars.minItems = gridSizeVideos;
						_this.flexsliderVideos.vars.maxItems = gridSizeVideos;

						var gridSizeProducts = _this.getGridSize( 1, 2, 3, 4, 4, 5 );
						_this.flexsliderProducts.vars.minItems = gridSizeProducts;
						_this.flexsliderProducts.vars.maxItems = gridSizeProducts;
					}
				});
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'finalizar_compra': {
			init: function() {
				// JavaScript to be fired on the about us page
				if ( $('#billing_invoice').val() == 'Factura' ) {
					$('.show_invoice').fadeIn();
				}
				else {
					$('.show_invoice').fadeOut();
					$('#billing_ruc').val('');
					$('#billing_company').val('');
					$('#billing_address_invoice').val('');
				}
				$('#billing_invoice').change( function ( ) {
					if ( $(this).val() == 'Factura' ) {
						$('.show_invoice').fadeIn();
					}
					else {
						$('.show_invoice').fadeOut();
						$('#billing_ruc').val('');
						$('#billing_company').val('');
						$('#billing_address_invoice').val('');
					}	
				} );




				if( $('#billing_district').val() == '(Yo recogeré mi pedido - SIN REPARTO)' ) {
					$('#billing_address_1').val('Calle Los Mogaburos 215. Departamento 208. Oficina 2. Jesús María. Lima-Perú.');
					$('#billing_reference').val('A una cuadra y veinte pasos del Hospital del Niño, cruzando la avenida Brasil, hacia el lado de Jesús María.');

					//$("#billing_address_1").prop('disabled', true);
					//$("#billing_reference").prop('disabled', true);

					$("#billing_address_1_field").removeClass('woocommerce-invalid');
					$("#billing_address_1_field label").html('Dirección para que recojas tu pedido');
					$("#billing_reference_field label").html('Referencia');
					$("#billing_address_1").after('<p style="font-size: 1rem; line-height: 1;">Calle Los Mogaburos 215. Departamento 208. Oficina 2. Jesús María. Lima-Perú.</p>');
					$("#billing_reference").after('<p style="font-size: 1rem; line-height: 1;">A una cuadra y veinte pasos del Hospital del Niño, cruzando la avenida Brasil, hacia el lado de Jesús María.</p>');
					$('#billing_address_1').fadeOut(0);
					$('#billing_reference').fadeOut(0);
				}
				else {
					$('#billing_address_1').val('');
					$('#billing_reference').val('');

					$("#billing_address_1").prop('disabled', false);
					$("#billing_reference").prop('disabled', false);
					$("#billing_address_1_field label").html('Dirección <abbr class="required" title="obligatorio">*</abbr>');
					$("#billing_reference_field label").html('Referencias (opcional)');
					$("#billing_address_1").next('p').remove();
					$("#billing_reference").next('p').remove();
					$('#billing_address_1').fadeIn(0);
					$('#billing_reference').fadeIn(0);
				}
				$('#billing_district').change( function ( ) {
					if ( $(this).val() == '(Yo recogeré mi pedido - SIN REPARTO)' ) {
						$('#billing_address_1').val('Calle Los Mogaburos 215. Departamento 208. Oficina 2. Jesús María. Lima-Perú.');
						$('#billing_reference').val('A una cuadra y veinte pasos del Hospital del Niño, cruzando la avenida Brasil, hacia el lado de Jesús María.');

						//$("#billing_address_1").prop('disabled', true);
						//$("#billing_reference").prop('disabled', true);

						$("#billing_address_1_field").removeClass('woocommerce-invalid');
						$("#billing_address_1_field label").html('Dirección para que recojas tu pedido');
						$("#billing_reference_field label").html('Referencia');
						$("#billing_address_1").after('<p style="font-size: 1rem; line-height: 1;">Calle Los Mogaburos 215. Departamento 208. Oficina 2. Jesús María. Lima-Perú.</p>');
						$("#billing_reference").after('<p style="font-size: 1rem; line-height: 1;">A una cuadra y veinte pasos del Hospital del Niño, cruzando la avenida Brasil, hacia el lado de Jesús María.</p>');
						$('#billing_address_1').fadeOut(0);
						$('#billing_reference').fadeOut(0);
					}
					else {
						$('#billing_address_1').val('');
						$('#billing_reference').val('');

						$("#billing_address_1").prop('disabled', false);
						$("#billing_reference").prop('disabled', false);
						$("#billing_address_1_field label").html('Dirección <abbr class="required" title="obligatorio">*</abbr>');
						$("#billing_reference_field label").html('Referencias (opcional)');
						$("#billing_address_1").next('p').remove();
						$("#billing_reference").next('p').remove();
						$('#billing_address_1').fadeIn(0);
						$('#billing_reference').fadeIn(0);
					}
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
